<template>
  <div>
    <getecma-form v-if="customer" :submit="save">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="customer.name"
            label="Nome*"
            name="nome"
            type="text"
            rules="required"
            placeholder="ex.: ABC Comércio de tapetes LTDA" />
        </div>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="customer.cnpj"
            label="CPF/CNPJ*"
            name="CPF/CNPJ"
            type="number"
            rules="required"
            maxlength="18"
            placeholder="CNPJ com 14 dígitos e CPF com 11 dígitos" />
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Unidade de Medida de Coletas*</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            name="unidade de medida"
            rules="required"
            placeholder="Selecione a unidade de medida"
            :value="capacitySelected.name"
            :options="capacities"
            @on-change="onCapacitiesChange">
          </getecma-select>
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Cidade*</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            name="cidade"
            rules="required"
            placeholder="Selecione a cidade"
            :value="citySelected.name"
            :options="cities"
            @on-change="onCityChange">
          </getecma-select>
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Bairro*</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            name="bairro"
            rules="required"
            placeholder="Selecione o bairro"
            :value="neighborhoodSelected.name"
            :options="neighborhoods"
            @on-change="onNeighborhoodChange">
          </getecma-select>
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Rota*</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            name="rota"
            rules="required"
            placeholder="Selecione a rota"
            :value="regionSelected.name"
            :options="regions"
            @on-change="onRegionChange">
          </getecma-select>
        </div>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="customer.address"
            label="Endereço*"
            name="endereço"
            type="text"
            rules="required"
            placeholder="ex.: Rua Juvenal Garcia - Centro, Itajaí, SC" />
        </div>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="customer.number"
            label="Número"
            name="número"
            type="number"
            placeholder="ex.: 289" />
        </div>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="customer.complement"
            label="Complemento"
            name="complemento"
            type="text"
            placeholder="ex.: Prédio Comercial, Segundo Andar, Sala 3" />
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Cliente por Acionamento?*</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            name="por acionamento"
            rules="required"
            placeholder="Informe a situação"
            :value="customer.per_activation === false ? 'Não' : 'Sim'"
            :options="statusPerActivation"
            @on-change="onStatusPerActivation">
          </getecma-select>
        </div>
      </div>
      <div v-if="!per_activation_validation">
        <div>
            <getecma-header size="sm" class="mt--xl">Recorrência*</getecma-header>
        </div>
        <div class="row mt--xl">
          <div class="form-field col-18 col-lg-9">
            <getecma-select
              name="recorrência"
              rules="required"
              placeholder="Selecione a recorrência"
              :value="recurrenceSelected"
              :options="recurrences"
              @on-change="onRecurrenceChange">
            </getecma-select>
          </div>
        </div>
        <div>
            <getecma-header size="sm" class="mt--xl">Dia da Semana Fixo?</getecma-header>
        </div>
        <div class="row mt--xl">
          <div class="form-field col-18 col-lg-9">
            <el-switch v-model="daYWeekFixed" active-text="Sim" inactive-text="Não" />
          </div>
        </div>
        <div v-if="daYWeekFixed">
          <div>
              <getecma-header size="sm" class="mt--xl"> Dia da Semana*</getecma-header>
          </div>
          <div class="row">
            <div class="form-field col-18 col-lg-9">
              <el-checkbox-group
                v-model="checkedDays"
                :max="selectedRecurrenceCount" class="row mt--xl">
                <el-checkbox v-for="(days, index) in dayWeekNamed" :key="index + 1" :label="index + 1">{{ days }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Status do Contrato*</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            name="status do contrato"
            rules="required"
            placeholder="Selecione o status"
            :value="customer.contract_status === false ? 'Inativo' : 'Ativo'"
            :options="statusContractOptions"
            @on-change="onStatusContractChange">
          </getecma-select>
        </div>
      </div>
      <div class="mt--xl mb--md d--flex justify-content-end">
        <getecma-button
          class="fs--xs"
          :round="false"
          size="lg"
          @click="goHistoryBack()">
          Cancelar
        </getecma-button>
        <getecma-button
          native-type="submit"
          class="fs--xs ms--md"
          :round="false"
          bg-color="success"
          size="lg">
          Salvar
        </getecma-button>
      </div>
    </getecma-form>
  </div>
</template>

<script>
import { toastError } from '@/services/toastService';
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';
import { getNeighborhoodById, fetchNeighborhoodsByCity } from '@/modules/neighborhood/neighborhood.service';
import { getRegionById, fetchRegionsByNeighborhood } from '@/modules/region/region.service';
import { getCapacityById, fetchCapacities } from '@/modules/capacity/capacity.service';
import { getRecurrenceById, fetchRecurrences } from '@/modules/recurrence/recurrence.service';
import { getCityById, fetchCities } from '@/modules/city/city.service';
import { FREQUENCIES } from '@/modules/recurrence/recurrence.constants';
import { STATUS_CONTRACT, STATUS_ACTIVATION } from '@/modules/customer/customer.constants';

export default {
  name: 'GetecmaCustomerCreateInformation',
  components: {
  },
  inject: ['customerEditVm'],
  data() {
    return {
      customer: this.customerEditVm.customer,
      performer: getters.getUser(),
      daYWeekFixed: false,
      dayWeekNamed: [/* 'Domingo', */'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira'/* , 'Sábado' */],
      statusContract: [0, 1],
      statusActivation: [0, 1],
      regions: [],
      capacities: [],
      recurrences: [],
      capacitySelected: null,
      citySelected: null,
      neighborhoodSelected: null,
      regionSelected: null,
      recurrenceSelected: null,
      selectedRecurrence: {
        number: null,
        frequency: null,
      },
      per_activation_validation: false,
      cities: [],
      neighborhoods: [],
      checkedDays: [],
    };
  },
  computed: {
    selectedRecurrenceCount() {
      return this.selectedRecurrence.number;
    },
    statusContractOptions() {
      return this.statusContract.map(status => ({
        key: status,
        value: STATUS_CONTRACT[status],
      }));
    },
    statusPerActivation() {
      return this.statusActivation.map(status => ({
        key: status,
        value: STATUS_ACTIVATION[status],
      }));
    },
  },
  mounted() {
    this.fetchCapacities();
    this.fetchRecurrences();
    this.fetchCities();
    this.fetchCapacity();
    this.fetchCity();
    this.per_activation_validation = this.customer.per_activation;
    if (this.customer.checked_days !== null) {
      this.daYWeekFixed = true;
    }
    if (this.customer.recurrence_id) {
      this.fetchRecurrence();
    }
  },
  methods: {
    goHistoryBack,
    async fetchRecurrence() {
      getRecurrenceById(this.customer.recurrence_id)
        .then(data => {
          const recurrenceValue = `${data.number} ${Object.values(FREQUENCIES)[data.frequency - 1].value}`;
          this.recurrenceSelected = recurrenceValue;
          this.defineMaxDays(recurrenceValue);
          if (this.daYWeekFixed) {
            // eslint-disable-next-line prefer-const
            let arrayCheckedDays = this.customer.checked_days.split(';');
            for (let i = 0; i < arrayCheckedDays.length; i++) {
              arrayCheckedDays[i] = parseInt(arrayCheckedDays[i]);
            }
            this.checkedDays = arrayCheckedDays;
          }
        })
        .catch(() => toastError('Erro ao obter recorrência por ID'));
    },
    async fetchCapacity() {
      getCapacityById(this.customer.capacity_id)
        .then(data => {
          this.capacitySelected = data;
        })
        .catch(() => toastError('Erro ao obter capacidade por ID'));
    },
    async fetchCity() {
      getCityById(this.customer.city_id)
        .then(async data => {
          this.citySelected = data;
          await this.onCityChange(data.id);
          await this.fetchNeighborhood();
        })
        .catch(() => toastError('Erro ao obter cidade por ID'));
    },
    async fetchNeighborhood() {
      getNeighborhoodById(this.customer.neighborhood_id)
        .then(async data => {
          this.neighborhoodSelected = data;
          await this.onNeighborhoodChange(data.id);
          await this.fetchRegion();
        })
        .catch(() => toastError('Erro ao obter bairro por ID'));
    },
    async fetchRegion() {
      getRegionById(this.customer.region_id)
        .then(async data => {
          this.regionSelected = data;
        })
        .catch(() => toastError('Erro ao obter rota por ID'));
    },
    async fetchCities() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchCities(params);
        this.cities = response.rows.map(city => ({
          key: city.id,
          value: city.name,
        }));
      } catch (error) {
        console.error('Erro ao buscar as cidades:', error);
      }
    },
    async fetchCapacities() {
      try {
        const params = { limit: 'all', page: 1, search: '', type: 0 };
        const response = await fetchCapacities(params);
        this.capacities = response.rows.map(capacity => ({
          key: capacity.id,
          value: `${capacity.name} - ${capacity.liters} litros`,
        }));
      } catch (error) {
        console.error('Erro ao buscar as unidades de medida:', error);
      }
    },
    async fetchRecurrences() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchRecurrences(params);
        this.recurrences = response.rows.map(recurrence => ({
          key: recurrence.id,
          value: `${recurrence.number} ${Object.values(FREQUENCIES)[recurrence.frequency - 1].value}`,
        }));
      } catch (error) {
        console.error('Erro ao buscar as recorrências:', error);
      }
    },
    save() {
      this.customer.checked_days = this.checkedDays.join(';');
      this.$emit('save');
    },
    async onCityChange(city) {
      let cityId = null;
      if (city.key) {
        cityId = city.key;
      } else {
        cityId = city;
      }
      this.customer.city_id = cityId;
      try {
        const response = await fetchNeighborhoodsByCity(cityId);
        this.neighborhoods = response.rows.map(neighborhood => ({
          key: neighborhood.id,
          value: neighborhood.name,
        }));
      } catch (error) {
        console.error('Erro ao buscar as rotas do bairro:', error);
      }
    },
    async onNeighborhoodChange(neighborhood) {
      let neighborhoodId = null;
      if (neighborhood.key) {
        neighborhoodId = neighborhood.key;
      } else {
        neighborhoodId = neighborhood;
      }
      this.customer.neighborhood_id = neighborhoodId;
      try {
        const response = await fetchRegionsByNeighborhood(neighborhoodId);
        this.regions = response.rows.map(region => ({
          key: region.id,
          value: region.name,
        }));
      } catch (error) {
        console.error('Erro ao buscar as rotas do bairro:', error);
      }
    },
    onCapacitiesChange(capacity) {
      this.customer.capacity_id = capacity.key;
    },
    onRegionChange(region) {
      this.customer.region_id = region.key;
    },
    onRecurrenceChange(recurrence) {
      this.customer.recurrence_id = recurrence.key;
      this.defineMaxDays(recurrence.value);
    },
    defineMaxDays(recurrenceValue) {
      const [recurrenceNumberString, , ...rest] = recurrenceValue.split(' ');
      const recurrenceFrequency = rest.pop(); // Pega a última palavra do array
      const recurrenceNumber = parseInt(recurrenceNumberString);

      if (recurrenceFrequency === 'semana') {
        this.selectedRecurrence = {
          number: recurrenceNumber,
          frequency: recurrenceFrequency,
        };
      } else {
        this.selectedRecurrence = {
          number: 1,
          frequency: recurrenceFrequency,
        };
      }
    },
    onStatusContractChange(statusContract) {
      this.customer.contract_status = statusContract.key;
    },
    onStatusPerActivation(statusActivation) {
      this.customer.per_activation = statusActivation.key;
      if (statusActivation.key === 0) {
        this.per_activation_validation = false;
      } else {
        this.per_activation_validation = true;
        this.customer.checked_days = null;
      }
    },
  },
};
</script>
